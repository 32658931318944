import styled from '@emotion/styled'
import { Item, Props as ItemProps } from 'app/components/Info/Item'
import React, { memo } from 'react'
import { Accordion } from 'react-accessible-accordion'

import { Icon } from './Icon'

export interface Props {
  items: ItemProps[]
  subtitle?: string
  title?: string
}

export const Faqs = memo(function Faqs({ items, subtitle, title }: Props) {
  if (!items) {
    return null
  }

  return (
    <Container>
      <Icon />

      {title ? <Title>{title}</Title> : null}

      {subtitle ? <Subtitle>{subtitle}</Subtitle> : null}

      <List allowZeroExpanded>
        {items.map((item, index) => (
          <Item key={index} {...item} />
        ))}
      </List>
    </Container>
  )
})

const Container = styled.section`
  color: ${({ theme }) => theme.colors.variants.neutralDark3};
  margin: 11.75rem auto;
  padding: 0 2.25rem;
  position: relative;
  text-align: center;

  @media (max-width: 1199px) {
    margin-bottom: 6.25rem;
    padding: 0 1.5rem;
  }
`

const Title = styled.h1`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.8125rem;
  font-weight: 300;
  line-height: 3.4375rem;

  @media (max-width: 1199px) {
    font-size: 2.5rem;
    line-height: 2.8125rem;
  }
`

const Subtitle = styled.h2`
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.0625rem;
  font-weight: 200;
  line-height: 2.125rem;
  margin-top: 1.125rem;

  @media (max-width: 1199px) {
    margin-top: 0.75rem;
  }
`

const List = styled(Accordion)`
  max-width: 53.125rem;
  margin: 4.25rem auto 0;

  > div {
    border-bottom: 0;
    &:last-of-type {
      border-bottom: 0.0625rem solid
        ${({ theme }) => theme.colors.variants.neutralDark3};
    }
  }

  @media (max-width: 1199px) {
    margin-top: 3.125rem;
  }
`
